import React, { useRef, useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { HashLoader } from 'react-spinners'

// Fix the default marker icon issue
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";
import RenderComponent from "./MapSubComponents/RenderComponent";
import { useDispatch, useSelector } from 'react-redux'
import "@luomus/leaflet-smooth-wheel-zoom";
import { CalculateCenterOfShape } from "../../lib/ShapeHandler";
import { useSearchParams } from "react-router-dom";
import SearchControl from "../../lib/Map/CustomControls/SearchControl";
import MarkerEditor from "./MapSubComponents/MarkerEditor";
import MarkerControl from "../../lib/Map/CustomControls/CreateMarkerControl";
import Geoman from "./Geoman";
import { setSelectedPolygon } from "../../state/slices/SelectedPolygonSlice";

L.Marker.prototype.options.icon = L.icon({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowAnchor: [12, 41],
  iconSize: [25, 41],
  shadowSize: [41, 41],
});

L.Map.prototype.options.maxZoom = 25;

const InteractiveMap = ({ satelite }) => {
  // State to store the description of the clicked marker
  const mapRef = useRef();
  const selectedPolygon = useSelector(state => state.selectedPolygon.selected);
  const mapPosition = useSelector(state => state.mapPosition);
  const polygons = useSelector(state => state.polygons);
  const roles = useSelector(state => state.auth.roles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedPolygon(null))
  }, [dispatch])

  if(polygons.length === 0){
    return (
      <div className="h-full w-full flex flex-row justify-center items-center">
          <HashLoader />
      </div>
    );
  }

  return (
    <div className="h-dvh w-full pb-16 md:pb-0">
      <MapContainer
        ref={mapRef}
        center={mapPosition.centerPosition}
        zoom={mapPosition.zoom}
        scrollWheelZoom={true}
        
        style={{ height: "100%", width: "100%" }}>
        {satelite ? (
          <TileLayer
            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            maxZoom={20}
            subdomains={["mt1", "mt2", "mt3"]}
          />
        ) : (
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        )}

        <RenderComponent />
        {roles.includes("Mapzie.Administrator")?  <Geoman /> : null }
       

        <MarkerEditor />
        <MapSettings />

        <SearchControl />
        <MarkerControl />
        
        <SelectPolygonRender selectedPolygon={selectedPolygon} />
      </MapContainer>
    </div>
  );
};

const MapSettings = () => {
  const map = useMap();
  map.scrollWheelZoom.disable();
  map.options.smoothSensitivity = 2;
  map.options.smoothWheelZoom = true;
  map.zoomControl.setPosition(window.innerWidth <= 768? "bottomright": "topleft")

  const markers = useSelector((state) => state.markers);
  let [searchParams, setSearchParams] = useSearchParams();

  /* Get pre-picked marker */
  const predefinedMarkerId = searchParams.get("marker");
  if (predefinedMarkerId) {
    const targetMarker = markers.find((marker) => marker.id === predefinedMarkerId);
    if (targetMarker) {
      const { position } = targetMarker;
      map.setView(position, 19); // Zoom to level 18 or any preferred zoom level
      setSearchParams("");
    }
  }

  return (null);;
}

const SelectPolygonRender = ({ selectedPolygon }) => {
  const map = useMap();

  useEffect(() => {
    if (selectedPolygon !== null && selectedPolygon !== undefined) {
      const polygonZoom = selectedPolygon.index === 1 ? 18 : 19;
      if(map.getZoom() < polygonZoom){
        map.setView(CalculateCenterOfShape(selectedPolygon.position), polygonZoom);
      } else {
        map.setView(CalculateCenterOfShape(selectedPolygon.position), map.getZoom());
      }
      

      // Create the overlay
      const overlay = L.polygon(selectedPolygon.position, {
        interactive: false,
        color: "white",
        fillColor: "white",
        weight: 4,
        opacity: 1,
        pmIgnore: true
      });

      // Add the overlay to the map
      map.addLayer(overlay);

      // Cleanup function to remove the overlay when dependencies change
      return () => {
        map.removeLayer(overlay);
      };
    }
  }, [map, selectedPolygon]); // Dependencies of useEffect

  return null; // Since this is a renderless component
};

export default InteractiveMap;
