import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import { store } from './state/Store';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
        <ToastContainer 
            position="bottom-left"
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
        />
    </Provider>
);