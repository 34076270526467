import { Marker, useMap } from "react-leaflet";
import L from 'leaflet';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@geoman-io/leaflet-geoman-free";
import { UpdatePolygon } from "../../../lib/ApiHandler/ShapeApi";
import { updatePolygon } from "../../../state/slices/PolygonSlice";
import { setSelectedPolygonAndEdit } from "../../../state/slices/SelectedPolygonSlice";
import { GenerateShapeFromShape } from "../../../lib/ShapeHandler";

const StoragePolygon = ({ polygon }) => {
  const map = useMap();
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());
  const dispatch = useDispatch();
  const jwtToken = useSelector(state => state.auth.jwtToken);
  const currentSelectd = useSelector(state => state.selectedPolygon.selected);

  useEffect(() => {
    // Update zoom level when the map is zoomed
    const handleZoom = () => setZoomLevel(map.getZoom());
    map.on("zoomend", handleZoom);
    return () => map.off("zoomend", handleZoom);
  }, [map]);

  useEffect(() => {
    // Initialize Geoman for the specific polygon
    const layer = GenerateShapeFromShape(polygon)

    // Add layer to map
    layer.addTo(map);

    // Listen for Geoman events
    layer.on("pm:update", (e) => {
      const positions = [];
      // Retrieve the updated positions from the edited layer
      e.layer._latlngs[0].forEach((element) => {
        positions.push([element.lat, element.lng]);
      });
      let toUpdate = {...polygon};
      toUpdate.position = positions;
      UpdatePolygon(toUpdate, jwtToken, (updatedFromMongo) => {
        dispatch(updatePolygon(updatedFromMongo))
      })

      /* Rerender the selected */
      if(currentSelectd?.id === toUpdate.id){
        dispatch(setSelectedPolygonAndEdit(toUpdate))
      }
    });

    layer.on("pm:edit", (e) => {
      console.log("Polygon editing started:", e);
    });

    /* When dragging an item, we do not want to trigger "click" event. */
    let isDragging = false;
    layer.on("pm:dragstart", () => {
      isDragging = true;
    });
  
    layer.on("pm:dragend", () => {
      isDragging = false;
    });

    layer.on("click", (e) => {
      if (isDragging) return;
      if (map.pm.globalDrawModeEnabled()) return;
      // Check if draw mode is active

      map.pm.disableGlobalRotateMode();
      dispatch(setSelectedPolygonAndEdit(polygon))
    })

    // Cleanup on component unmount
    return () => {
      map.removeLayer(layer);
    };
  }, [map, polygon, currentSelectd, dispatch, jwtToken]);

  function getCentroid(points) {
    let lat = 0, lng = 0;
    points.forEach((p) => {
      lat += (p[0] * Math.PI) / 180;
      lng += (p[1] * Math.PI) / 180;
    });
    lat /= points.length;
    lng /= points.length;

    return [(lat * 180) / Math.PI, (lng * 180) / Math.PI];
  }

  // Map zoom levels to specific marker and font sizes
  const getMarkerStyles = (zoom) => {
    if (zoom >= 19) return { markerSize: 65, fontSize: 13, multiplier: 9 };
    if (zoom > 18) return { markerSize: 40, fontSize: 12, multiplier: 8 };
    if (zoom > 17) return { markerSize: 25, fontSize: 8, multiplier: 7 };
    if (zoom > 16) return { markerSize: 15, fontSize: 5, multiplier: 4 };
    if (zoom > 15) return { markerSize: 10, fontSize: 3, multiplier: 3 };
    if (zoom > 14) return { markerSize: 5, fontSize: 1, multiplier: 1 };
    return { markerSize: 20, fontSize: 6 }; // Default size for other zoom levels
  };

  const { markerSize, fontSize, multiplier } = getMarkerStyles(zoomLevel);

  return (
    <>
      {/* Marker for the centroid */}
      <Marker
        position={getCentroid(polygon.position)}
        interactive={false}
        icon={L.divIcon({
          html: `<center><p style="font-size:${fontSize}px">${polygon.name}</p></center>`,
          iconSize: [polygon.name.length * multiplier, markerSize / 2],
        })}
      />
    </>
  );
};

export default StoragePolygon;
