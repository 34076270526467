
import { createSlice } from '@reduxjs/toolkit'

const AuthSlice = createSlice({
  name: 'AuthSlice',
  initialState: {
    jwtToken: null,
    name: "",
    username: "",
    roles: []
  },
  reducers: {
    setJwtToken(state, action){
      return {...action.payload}
    },
    invalidateJwtToken(){
        return null;
    }
  }
})

export const { setJwtToken, invalidateJwtToken } = AuthSlice.actions
export default AuthSlice.reducer

