

const DangerButton = ({children, props, onClick}) => {
    return (
        <button {...props} onClick={onClick} className="text-white bg-red-700 hover:bg-red-800 p-2 font-medium rounded-lg">
            {children}
        </button>
    );
}

export default DangerButton