
import { createSlice } from '@reduxjs/toolkit'

const SelectedPolygon = createSlice({
  name: 'SelectedPolygonSlice',
  initialState: {
    selected: null,
    dispalyEdit: false
  },
  reducers: {
    setSelectedPolygon(state, action){
      return {
        selected: action.payload,
        dispalyEdit: false
      };
    },
    setSelectedPolygonAndEdit(state, action){
      return {
        selected: action.payload,
        dispalyEdit: true
      };
    }
  }
})

export const { setSelectedPolygon, setSelectedPolygonAndEdit } = SelectedPolygon.actions
export default SelectedPolygon.reducer

