import { useCallback, useEffect } from "react";
import ReactDOM from 'react-dom'

const FullScreenImage = ({ imageUrl, onClose }) => {
    const handleKeyDown = useCallback((e) => {
      if (e.key === "Escape") {
        onClose();
      }
    }, [onClose]);
  
    useEffect(() => {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleKeyDown]);
  
    return (
      ReactDOM.createPortal(
        <div
        className="fullscreen-overlay fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center z-50 p-4 md:p-52"
        onClick={onClose}
        style={{zIndex: 3000}}
      >
        <img
          src={imageUrl}
          alt="Full Screen"
          className="max-w-full max-h-full object-contain"
        />
      </div>,
        document.body
      )
    );
  };

export default FullScreenImage