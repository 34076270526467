
import { createSlice } from '@reduxjs/toolkit'

const ViewPositionSlice = createSlice({
  name: 'ViewPositionSlice',
  initialState: {
    zoom: 17,
    centerPosition: [62.997197144463605, 17.686999353655548]
  },
  reducers: {
    setMapPosition(state, action){
        return {
            zoom: action.payload.zoom,
            centerPosition: action.payload.centerPosition
        }
    }
  }
})

export const { setMapPosition } = ViewPositionSlice.actions
export default ViewPositionSlice.reducer

