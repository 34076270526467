import { APP_USER_ROLE } from '../Constants';
import { PUBLIC_CLIENT_APPLICATION, LOGIN_REQUEST, TOKEN_REQUEST } from './msalConfig'

export const handleSignIn = async (callback) => {
    try {
        const loginResponse = await PUBLIC_CLIENT_APPLICATION.loginPopup(LOGIN_REQUEST);
        if (loginResponse.account) {
            // Set the active account after login
            PUBLIC_CLIENT_APPLICATION.setActiveAccount(loginResponse.account);
        }
        // Acquire token again after interactive login
        const tokenResponse = await PUBLIC_CLIENT_APPLICATION.acquireTokenSilent(TOKEN_REQUEST);
        const credentials = getUserCredentials(tokenResponse);
        if(!userHasAppAccess(credentials)){
            return callback("Missing Role");
        }

        callback(credentials);
    } catch (loginError) {
        console.error("Error during loginPopup:", loginError);
    }
}

export const silentLogin = async (callback) => {
    try{
        const tokenResponse = await PUBLIC_CLIENT_APPLICATION.acquireTokenSilent(TOKEN_REQUEST);
        const credentials = getUserCredentials(tokenResponse);
        if(!userHasAppAccess(credentials)){
            return callback("Missing Role");
        }

        callback(credentials);
    } catch (error) {
        callback(null)
    }
}

export const handleSignOut = async (callback) => {
    await PUBLIC_CLIENT_APPLICATION.logoutPopup();
    callback()
};

const userHasAppAccess = (credentials) => {
    return credentials.roles.includes(APP_USER_ROLE);
}

export function GetUserRoles(token) {
    const arrayToken = token.split('.');
    const tokenPayload = JSON.parse(atob(arrayToken[1]));
    return tokenPayload.roles;
}

const getUserCredentials = (tokenResponse) => {
    return {
        username: tokenResponse.idTokenClaims.preferred_username,
        name: tokenResponse.idTokenClaims.name,
        userGroups: tokenResponse.idTokenClaims.groups,
        roles: GetUserRoles(tokenResponse.accessToken),
        jwtToken: tokenResponse.accessToken
    }
}