import { configureStore } from '@reduxjs/toolkit'
import PolygonReducer from './slices/PolygonSlice'
import SelctedPolygonReducer from './slices/SelectedPolygonSlice'
import EditModeReducer from './slices/EditModeSlice'
import MarkerReducer from './slices/MarkerSlice'
import SasTokenReducer from './slices/SasTokenSlice'
import ViewPositionReducer from './slices/ViewPositionSlice'
import AuthReducer from './slices/AuthSlice'

export const store = configureStore({
  reducer: {
    polygons: PolygonReducer,
    selectedPolygon: SelctedPolygonReducer,
    editMode: EditModeReducer,
    markers: MarkerReducer,
    sasToken: SasTokenReducer,
    mapPosition: ViewPositionReducer,
    auth: AuthReducer
  }
})