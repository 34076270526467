import { Link, useSearchParams } from "react-router-dom";
import { FaImages } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteMarker, UpdateMarker } from "../../../lib/ApiHandler/MarkerApi";
import { removeMarker, updateMarker } from "../../../state/slices/MarkerSlice";
import { GetTimeSinceAction } from "../../../lib/ShapeHandler";
import ApproveDenyModal from "../../../components/RootElements/ApproveDenyModal";
import ShowEditMarkerModal from "../../../components/Map/MapSubComponents/ShowEditMarkerModal";

const MarkersTable = () => {
    const jwtToken = useSelector(state => state.auth.jwtToken);
    const [searchString, setSearchString] = useState("");
    const [activeTab, setActiveTab] = useState("markers"); // 'markers' or 'handledMarkers'
    const markers = useSelector(state => state.markers);
    const sasToken = useSelector(state => state.sasToken);
    let [searchParams, setSearchParams] = useSearchParams();

    const setSearch = (e) => {
        setSearchString(e.target.value.toLowerCase());
    };

    const filteredMarkers = markers.filter(x =>
        (x.name.toLowerCase().includes(searchString) || x.description.toLowerCase().includes(searchString)) && (x.deleted !== true)
    );

    const handledMarkers = markers.filter(marker => 
        marker.deleted === true && (marker.name.toLowerCase().includes(searchString) || marker.description.toLowerCase().includes(searchString)))

    const renderMarkers = (data) => {
        return data
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .map(marker => (
                <TableItem marker={marker} jwtToken={jwtToken} sasToken={sasToken} key={marker.id} searchParams={searchParams} setSearchParams={setSearchParams} />
            ));
    };

    return (
        <div>
            {/* Tabs */}
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                    <li className="me-2">
                        <button
                            className={`inline-block p-4 border-b-2 rounded-t-lg ${
                                activeTab === "markers"
                                    ? "text-blue-600 border-blue-600"
                                    : "text-gray-500 hover:text-gray-600 hover:border-gray-300"
                            }`}
                            onClick={() => setActiveTab("markers")}
                        >
                            Markers
                        </button>
                    </li>
                    <li className="me-2">
                        <button
                            className={`inline-block p-4 border-b-2 rounded-t-lg ${
                                activeTab === "handledMarkers"
                                    ? "text-blue-600 border-blue-600"
                                    : "text-gray-500 hover:text-gray-600 hover:border-gray-300"
                            }`}
                            onClick={() => setActiveTab("handledMarkers")}
                        >
                            Hanterade markers
                        </button>
                    </li>
                </ul>
            </div>

            {/* Table */}
            <div className="relative shadow-md sm:rounded-lg mt-4">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                        <div className="relative mt-3">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg
                                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                    />
                                </svg>
                            </div>
                            <input
                                onChange={setSearch}
                                type="text"
                                id="table-search"
                                className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Sök efter markers"
                            />
                        </div>
                    </caption>
                    {activeTab === "markers"
                            ? <MarkersTableHead />
                            : <HandledMarkersTableHead /> }
                    
                    <tbody className="max-h-96 overflow-y-scroll">
                        {activeTab === "markers"
                            ? renderMarkers(filteredMarkers)
                            : renderMarkers(handledMarkers)}
                    </tbody>
                </table>
            </div>
        </div>
    );
};


const TableItem = ({marker, jwtToken, sasToken, searchParams, setSearchParams}) => {

    const [showModal, setShowModal] = useState(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
    const dispatch = useDispatch();

    const handleRemoveApprove = () => {
        DeleteMarker(marker, jwtToken, () => {
            dispatch(removeMarker(marker))
        });
        setIsRemoveModalOpen(false);
    }

    useEffect(() => {
        /* Check if this marker is selecteed from the map*/
        const predefinedMarkerId = searchParams.get("markerid");
        if (predefinedMarkerId) {
            if(predefinedMarkerId === marker.id){
                setShowModal(true)
                setSearchParams("");
            }
        }
    }, [marker.id, searchParams, setSearchParams])
    
    const saveMarker = (name, description) => {
        let markerToUpdate = {...marker};
        markerToUpdate.name = name;
        markerToUpdate.description = description;
        UpdateMarker(markerToUpdate, jwtToken, (updatedMarker) => {
            dispatch(updateMarker(updatedMarker));
        })
    }

    return  (
        <>
            <tr className="bg-slate-50 border-b hover:bg-slate-100" onClick={() => {setShowModal(true)}}>
                {!marker.deleted?
                <td className="pl-3 text-left group ">
                    <Link to={"/map?marker=" + marker.id} className="font-medium text-slate-500 hover:underline text-xl">
                        <span class="m-0 bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Karta</span>
                    </Link>
                </td>:null}
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white w-96">
                    {marker.name}
                </th>
                <td className="px-6 py-4">
                    {marker.description.slice(0,100)}
                </td>
                <td className="px-6 py-4">
                    {marker.images.length}
                </td>
                <td className="px-6 py-4 w-48">
                    {GetTimeSinceAction(marker.updatedAt)}
                </td>
            </tr>
            {showModal? 
                <ShowEditMarkerModal marker={marker} isOpen={showModal} onSave={saveMarker} onClose={() => { setShowModal(false) }}/>
            : null}
            {isRemoveModalOpen &&
                <ApproveDenyModal onAccept={handleRemoveApprove} onDeny={() => {setIsRemoveModalOpen(false)}}>Är du säker på att du vill ta bort denna marker?</ApproveDenyModal>
            }
        </>
       
    );
}

const HandledMarkersTableHead = () => {
    return (
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">           
            <tr>
                <th scope="col" className="px-6 py-3">Titel</th>
                <th scope="col" className="px-6 py-3">Beskrivning</th>
                <th scope="col" className="px-6 py-3">
                    <FaImages />
                </th>
                <th scope="col" className="px-6 py-3">Senast ändrad</th>
            </tr>
        </thead>
    );
}

const MarkersTableHead = () => {
    return (
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">           
            <tr>
                <th scope="col" className="pl-6 py-3">Visa</th>
                <th scope="col" className="px-6 py-3">Titel</th>
                <th scope="col" className="px-6 py-3">Beskrivning</th>
                <th scope="col" className="px-6 py-3">
                    <FaImages />
                </th>
                <th scope="col" className="px-6 py-3">Senast ändrad</th>
            </tr>
        </thead>
    );
}

export default MarkersTable