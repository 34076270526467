import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { GetBlobUrl, GetSasToken, UploadBlobsAsync } from "../../../lib/ApiHandler/BlobApi";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import FullScreenImage from "../../RootElements/FullScreenImage";
import { DeleteMarker, RehydrateMarker, UpdateMarker } from "../../../lib/ApiHandler/MarkerApi";
import { updateMarker } from "../../../state/slices/MarkerSlice";
import LoadingOverlay from "../../RootElements/LoadingOverlay";
import resizeImage from "../../../lib/ImageResize";
import { APP_ADMIN_ROLE } from "../../../lib/Constants";
import DropDownButton from "../../Buttons/DropDownButton";
import { IoSettingsOutline } from "react-icons/io5";
import ApproveDenyModal from "../../RootElements/ApproveDenyModal";
import { Link } from "react-router-dom";

const ShowEditMarkerModal = ({ isOpen, onClose, onSave, marker }) => {
  if (!isOpen) return null;

  const handleSave = (title, description, imageName) => {
    onSave(title, description);
    onClose();
  };

  return ReactDOM.createPortal(<MarkerModal handleSave={handleSave} onClose={onClose} marker={marker} />, document.body);
};


const MarkerModal = ({handleSave, onClose, marker}) => {
    const markerTitle = useRef();
    const markerDescription = useRef();
    const [isLoading, setIsLoading] = useState();
    const jwtToken = useSelector(state => state.auth.jwtToken);
    const roles = useSelector(state => state.auth.roles)
    const [images, setImages] = useState([]);
    const sasToken = useSelector(state => state.sasToken);
    const [fullScreenImage, setFullScreenImage ] = useState(null);
    const uploadFileRef = useRef();
    const [markAsHandledModal, setMarkAsHandledModal] = useState();
    const dispatch = useDispatch();

    const handleUpload = () => {
        uploadFileRef.current.click()
    };
    
    const isAdmin = () => roles.includes(APP_ADMIN_ROLE);
    
    const markAsHandled = () => {
        DeleteMarker(marker,jwtToken, () => {
          dispatch(updateMarker({...marker, deleted: true}))
        });
        setMarkAsHandledModal(false);
        onClose();
      };
    
    const rehydrateMarker = () => {
        RehydrateMarker(marker.id,jwtToken, () => {
            dispatch(updateMarker({...marker, deleted: false}))
        });
        onClose()
    }

    const uploadImage = async (event) => {
        if(event.target.files){
            setIsLoading(true)
            GetSasToken(jwtToken, async (sastoken) => {
                let blobs = []
                for (let index = 0; index < event.target.files.length; index++) {
                    blobs.push(resizeImage(event.target.files[index]))
                }     
                UploadBlobsAsync(sastoken, blobs, (uploadedBlobNames) => {
                    UpdateMarker({
                        ...marker,
                        images: [...marker.images, ...uploadedBlobNames]
                    }, jwtToken, (resultMarker) => {
                        console.log(resultMarker);
                        dispatch(updateMarker(resultMarker))
                        setIsLoading(false)
                    })   
                })
            }) 

        }
    }

    const updateMarkerOnSave = () => {
        handleSave(markerTitle.current.value, markerDescription.current.value);
    }

    const showImageFullScreen = (imageUrl) => {
        setFullScreenImage(imageUrl)
    }

    const overlayId = "overlayxx"

    useEffect(() => {
        const clickOutBounds = (event) => {
            if(event.target.id === overlayId){
                onClose()
            }
        }

        const onEscapePress = (event) => {
            if (event.key === "Escape") {
                event.preventDefault();
                onClose()
            }
        }

        window.addEventListener("click", clickOutBounds)
        window.addEventListener("keydown", onEscapePress)

        let images = []
        marker.images.forEach(image => {
            GetBlobUrl(sasToken, image, (imgUrl) => {
                images.push({url: imgUrl, name: image})
            })
        });
        setImages(prev => [...images]);

        return () => {
            window.removeEventListener("click", clickOutBounds);
            window.removeEventListener("keydown", onEscapePress);
        };

    }, [onClose, marker.images, sasToken])

    const markerActions = () => {
        if(marker.deleted){
            return [
                { 
                    text: "Återskapa", 
                    action: () => {rehydrateMarker(true)}
                }
            ]
        } 
        return [{ text: "Klarmarkera", action: () => {setMarkAsHandledModal(true)}}]
    }

  return (
    <div
      tabIndex="-1"
      aria-hidden="true"
      id={overlayId}
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] h-screen bg-black bg-opacity-50"
      style={{ zIndex: 2999 }}>
      <div className="relative p-4 w-full max-w-2xl max-h-full mx-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="p-4 md:p-5 space-y-4">
           <div className="flex flex-row justify-between">
            {/* CreatedBy and Deletec By */}
            <div>
                <div className="mt-0">
                        <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Skapad av: {marker.createdBy}</label>
                </div>
                {marker.deletedBy?
                    <div className="mt-4">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-900 dark:text-white">Hanterad av: {marker.deletedBy}</label>
                    </div>
                :null}
            </div>

            {/* Actions */}
            {isAdmin()?
                <div>
                <DropDownButton actions={markerActions()}> <SecondaryButton><IoSettingsOutline/></SecondaryButton></DropDownButton>
                </div>
            : null}
            
           </div>
            <div className="my-6">
                <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titel</label>
                <input disabled={!isAdmin() || marker.deleted} defaultValue={marker.name} ref={markerTitle} placeholder="Marker title..." type="text" id="title" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
            </div>
            <div>
                <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Beskrivning</label>
                <textarea disabled={!isAdmin() || marker.deleted} defaultValue={marker.description} ref={markerDescription} id="description" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Beskrivning..."></textarea>
            </div>
            {marker.deleted !== true? 
                <div>
                    <Link to={"/map?marker=" + marker.id} className="font-medium text-slate-500 hover:underline text-xl">
                        <span class="m-0 bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Visa på karta</span>
                    </Link>
                </div>
            :null}
            
            <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bilder ({marker.images.length})</label>
                <div className="grid grid-cols-5 gap-4">
                    {images.map((image) => {
                        return (
                            <div key={Math.random()} className="w-full aspect-[9/16] overflow-hidden rounded-lg" >
                                <ImageContainer showImageFullScreen={showImageFullScreen} image={image.url}/>
                            </div>
                        );
                    })}
                    {isAdmin() && marker.deleted !== true?
                     <div className="w-full aspect-[9/16] overflow-hidden rounded-lg" >
                        <div className="w-full h-full object-cover">
                            <div className="w-full h-full rounded-xl border border-gray-500 p-2 flex flex-col justify-center shadow-xl border-dashed hover:bg-neutral-50 hover:cursor-pointer" onClick={() => handleUpload()}>
                                <input
                                    type="file"
                                    multiple={true}
                                    accept="image/*"
                                    ref={uploadFileRef}
                                    onChange={uploadImage}
                                    style={{ opacity: "0" }}
                                    className="absolute w-0 h-0"
                                />
                                <p className="mx-auto">Lägg till bild</p>
                            </div>
                        </div>
                    </div>
                    :null}
                   
                </div>
            </div>
          </div>

          <div className="flex justify-between items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            {isAdmin() && !marker.deleted? <PrimaryButton onClick={updateMarkerOnSave}>Uppdatera Marker</PrimaryButton>: null}
            <SecondaryButton onClick={() => {onClose()}}>Stäng</SecondaryButton>
          </div>
        </div>
      </div>
      {fullScreenImage &&
        <FullScreenImage imageUrl={fullScreenImage} onClose={() => {
            setFullScreenImage(null)
        }}/>
        }
        {isLoading? <LoadingOverlay />: null}
        {markAsHandledModal &&
            <ApproveDenyModal onAccept={markAsHandled} onDeny={() => {setMarkAsHandledModal(false)}}>Är du säker på att du vill ta bort denna marker?</ApproveDenyModal>
        }
    </div>
  );
};

const ImageContainer = ({showImageFullScreen, image}) => {
    return (
        <img
            src={image}
            alt="Imasge"
            className="w-full h-full object-cover"
            onClick={() => showImageFullScreen(image)}
        />
    ); 
}

export default ShowEditMarkerModal;
