import { NotifyError, NotifySuccess } from "../Notify/notify";


export const GetAllMarkers = (jwtToken, callback) => {
    fetch(process.env.REACT_APP_API_BASE_URL + '/markers', {
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    })
    .then(res => res.json())
    .then(res => {
      callback(res)
    })
    .catch(res => {
        NotifyError("Kunde inte hämta alla markers.");
    });
}

export const CreateMarker = (marker, jwtToken, callback) => {
    fetch(process.env.REACT_APP_API_BASE_URL + '/markers', {
        method: "POST",
        body: JSON.stringify({
            name: marker.name,
            description: marker.description,
            position: marker.position,
            images: marker.images
        }),
        headers: {
            "Content-Type": "Application/json",
            Authorization: `Bearer ${jwtToken}`
        }
    })
    .then(async res => {
        if(res.status >= 300){
            return res.text(); 
        } else {
            return res.json(); 
        }
    })
    .then(res => {
        NotifySuccess("Marker skapad!");
        callback(res)
    })
    .catch(res => {
        NotifyError("Kunde inte skapa marker.");
    });
}

export const UpdateMarker = (marker,jwtToken, callback) => {
    console.log("CALLED AN UPDATE");
    
    fetch(process.env.REACT_APP_API_BASE_URL + '/markers', {
        method: "PUT",
        body: JSON.stringify({
            name: marker.name,
            description: marker.description,
            position: marker.position,
            images: marker.images,
            id: marker.id
        }),
        headers: {
            "Content-Type": "Application/json",
            Authorization: `Bearer ${jwtToken}`
        }
    })
    .then(res =>  res.json())
    .then(res => {
        NotifySuccess("Marker uppdaterad!");
        callback(res)
    })
    .catch(async res => {
        console.log(res);
        
        NotifyError("Kunde inte uppdatera marker.");
    });
}

export const RehydrateMarker = (id, jwtToken, callback) => {
    fetch(process.env.REACT_APP_API_BASE_URL + '/markers/' + id + '/rehydrate', {
        method: "PUT",
        headers: {
            "Content-Type": "Application/json",
            Authorization: `Bearer ${jwtToken}`
        }
    })
    .then(res =>  res.json())
    .then(res => {
        NotifySuccess("Marker uppdaterad!");
        callback(res)
    })
    .catch(async res => {
        NotifyError("Kunde inte uppdatera marker.");
    });
}

export const DeleteMarker = (marker, jwtToken, callback) => {
    fetch(process.env.REACT_APP_API_BASE_URL + '/markers/'+marker.id, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    })
    .then(res => res.json())
    .then(res => {
        NotifySuccess("Marker borttagen!");
        callback(res)
    })
    .catch(res => {
        NotifyError("Kunde inte ta bort marker.");
    });
}

export const DeleteMarkerImage = (marker, imageName, jwtToken, callback) => {
    console.log("deleted");
    
    fetch(process.env.REACT_APP_API_BASE_URL + '/markers/'+marker.id+"/image/"+imageName, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    })
    .then(res => res.json())
    .then(res => {
        callback(res)
    })
    .catch(res => {
        NotifyError("Kunde inte ta bort marker-bild.");
    });
}
