

const SecondaryButton = ({children, props, onClick}) => {
    return (
        <button {...props} onClick={onClick} className="text-gray-900 bg-white p-2 font-medium hover:bg-gray-100 hover:text-blue-700 rounded-lg border border-gray-200">
            {children}
        </button>
    );
}

export default SecondaryButton