import { useState } from "react";
import { Marker, Popup } from "react-leaflet";
import ApproveDenyModal from "../../RootElements/ApproveDenyModal";
import { DeleteMarker } from "../../../lib/ApiHandler/MarkerApi";
import { setEditMode } from "../../../state/slices/EditModeSlice";
import { useDispatch, useSelector } from "react-redux";
import { removeMarker } from "../../../state/slices/MarkerSlice";
import { Link } from "react-router-dom";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { IoSettingsOutline } from "react-icons/io5";
import DropDownButton from "../../Buttons/DropDownButton";
import { FaImages } from "react-icons/fa";
import { APP_ADMIN_ROLE } from "../../../lib/Constants";


const StorageMarker = ({ marker }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const jwtToken = useSelector(state => state.auth.jwtToken)
  const roles = useSelector(state => state.auth.roles);

  const markAsHandled = () => {
    setIsModalOpen(true); // Show the modal
  };

  const isAdmin = () => roles.includes(APP_ADMIN_ROLE);

  const handleAccept = () => {
    DeleteMarker(marker,jwtToken, () => {
      dispatch(setEditMode(false));
      dispatch(removeMarker(marker))
    });
    setIsModalOpen(false); // Hide the modal after handling
  };

  const handleDeny = () => {
    setIsModalOpen(false); // Hide the modal if denied
  };

  return (
    <>
      <Marker
        position={marker.position}
        id={marker.id}
        key={marker.id}
      >
        <Popup className="w-96">
          <p className="text-xl font-bold m-0">{marker.name}</p>
          <p>{marker.description}</p>
          <p><FaImages className="inline"/> {marker.images.length}</p>
          
            <div className="flex flex-row justify-between mt-4 w-full">
              <Link to={"/markers?markerid=" + marker.id}><PrimaryButton>Visa mer information</PrimaryButton></Link>
              {isAdmin()?
              <DropDownButton actions={[{
                text: "Klarmarkera",
                action: markAsHandled
              }]}>
                <SecondaryButton><IoSettingsOutline/></SecondaryButton>
                
              </DropDownButton>
              :null}
              
            </div>  

        </Popup>
      </Marker>

      {isModalOpen &&
        <ApproveDenyModal onAccept={handleAccept} onDeny={handleDeny}>Är du säker på att du vill ta bort denna marker?</ApproveDenyModal>
      }
    </>
  );
};

export default StorageMarker;
