import { createSlice } from '@reduxjs/toolkit'

const MarkerSlice = createSlice({
  name: 'MarkerSlice',
  initialState: [],
  reducers: {
    addMarker(state, action) {
      return [...state, action.payload];
    },
    setMarkers(state, action) {
      return [...action.payload];
    },
    removeMarker(state, action) {
      return state.filter(marker => marker.id !== action.payload.id);
    },
    updateMarker(state, action) {
      return state.map(marker => 
        marker.id === action.payload.id ? action.payload : marker
      );
    }
  }
})

export const { addMarker, removeMarker, updateMarker, setMarkers } = MarkerSlice.actions
export default MarkerSlice.reducer
