
import { createSlice } from '@reduxjs/toolkit'

const EditModeSlice = createSlice({
  name: 'EditModeSlice',
  initialState: false,
  reducers: {
    setEditMode(state, action){
      return action.payload;
    }
  }
})

export const { setEditMode } = EditModeSlice.actions
export default EditModeSlice.reducer

