import { useEffect, useState } from "react";
import StorageMarker from "../Shapes/StorageMarker";
import StoragePolygon from "../Shapes/StoragePolygon";
import { getVisiblePolygons } from '../../../lib/ShapeHandler'
import { GetSasToken } from "../../../lib/ApiHandler/BlobApi";
import { useSelector, useDispatch } from 'react-redux'
import { setSasToken } from "../../../state/slices/SasTokenSlice";

const RenderComponent = () => {
  const [loading, setLoading] = useState(true);
  
  const polygons = useSelector(state => state.polygons);
  const selectedPolygon = useSelector(state => state.selectedPolygon.selected);
  const markers = useSelector(state => state.markers);
  const jwtToken = useSelector(state => state.auth.jwtToken);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const GetTheSasToken = async () => {
      GetSasToken(jwtToken, (token) => {
        dispatch(setSasToken(token))
        setLoading(false)
      });
    }
    
    GetTheSasToken();
  }, [polygons, jwtToken, dispatch])
  
  if(loading){
    return null;
  }

  return (
    <>
      {markers.filter(x => x.deleted !== true).map((marker) => {
        return (
          <StorageMarker
            key={Math.random()}
            marker={marker}
          />
        );
      })}

      {/* Always print out index 1 polygons */}
      {
        getVisiblePolygons(polygons, selectedPolygon).map(polygon => {
          return (
            <StoragePolygon
              key={polygon.id}
              polygon={polygon}
            />
          );
        })
      }
    </>
  );
};

export default RenderComponent;
