import { useEffect, useRef, useState } from "react";
import { DeletePolygon, UpdatePolygon } from "../../../lib/ApiHandler/ShapeApi";
import { useDispatch, useSelector } from "react-redux";
import { removePolygon, updatePolygon } from "../../../state/slices/PolygonSlice";
import { setSelectedPolygon } from "../../../state/slices/SelectedPolygonSlice";
import ApproveDenyModal from "../../RootElements/ApproveDenyModal";
import { IoCloseSharp } from "react-icons/io5";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { IoIosSave } from "react-icons/io";
import { FaTrashCan } from "react-icons/fa6";
import DangerButton from "../../Buttons/DangerButton";
import { APP_ADMIN_ROLE } from "../../../lib/Constants";


const SelectedShapeOverlay = () => {

    const selectedPolygon = useSelector(state => state.selectedPolygon.selected);
    const [color, setColor] = useState(selectedPolygon?.fillColor)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const jwtToken = useSelector(state => state.auth.jwtToken);
    const userRoles = useSelector(state => state.auth.roles);
    const polyName = useRef();
    const polygons = useSelector(state => state.polygons)
    const polyDescription = useRef();
    const isDrawerOpen = useSelector(state => state.selectedPolygon.dispalyEdit);
    const dispatch = useDispatch();

    const isAdmin = () => {
      return userRoles.includes(APP_ADMIN_ROLE);
    }

    useEffect(() => {
      setColor(selectedPolygon?.fillColor)
    }, [selectedPolygon])

    const saveChanges = () => {
      const newPolygon = {...selectedPolygon}
      newPolygon.name = polyName.current.value;
      newPolygon.description = polyDescription.current.value;
      newPolygon.fillColor = color !== undefined ? color: selectedPolygon.fillColor;
      
      UpdatePolygon(newPolygon, jwtToken, (updatedPolygon) => {
        dispatch(updatePolygon(updatedPolygon))
      });
    }

    const removePolygonApprove = () => {
      DeletePolygon(selectedPolygon, jwtToken, () => {
        dispatch(removePolygon(selectedPolygon))
        dispatch(setSelectedPolygon(null))
        setIsModalOpen(false)
      });
    }

    if(selectedPolygon === null){
      return (
        <div>
          <div className={'bg-gray-50 border border-l border-gray-500 shadow-xl w-96 h-[calc(100dvh-100px)] md:h-[calc(100dvh-15px)] overflow-y-scroll right-0 top-2 fixed transition-all ' + (isDrawerOpen? "": "translate-x-full")} style={{zIndex: 3000}}>
          <div className="flex flex-col h-full justify-between">
            <div>
              <div className="p-4 flex flex-row justify-between">
                <p className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400">Markerad yta</p>
                <div onClick={() => {
                    dispatch(setSelectedPolygon(null))
                  }}><IoCloseSharp className="text-xl text-gray-600 hover:text-red-700"/></div>
              </div>
            </div>
          </div>
        </div>
        </div>
      );
     }
  

    return (
      <div>
        <div className={'bg-gray-50 border border-l border-gray-500 shadow-xl w-96 h-[calc(100dvh-100px)] md:h-[calc(100dvh-15px)] overflow-y-scroll right-0 top-2 fixed transition-all ' + (isDrawerOpen? "": "translate-x-full")}  style={{zIndex: 3000}}>
          <div className="flex flex-col h-full justify-between">
            <div>
              <div className="p-4 flex flex-row justify-between">
                <p className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400">Markerad yta</p>
                <div onClick={() => {
                  dispatch(setSelectedPolygon(null))
                }}><IoCloseSharp className="text-xl text-gray-600 hover:text-red-700"/></div>
              </div>
              
              <div className="space-y-4 px-4">
                <div className="">
                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titel</label>
                  <input disabled={!isAdmin()} ref={polyName} defaultValue={selectedPolygon.name} key={selectedPolygon.name} type="text" id="large-input" className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"} />
                </div>
                <div>
                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Beskrivning</label>
                  <textarea disabled={!isAdmin()} ref={polyDescription} key={selectedPolygon.description} defaultValue={selectedPolygon.description} id="description" rows="4" className={"block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"} placeholder="Beskrivning..."></textarea>
                </div>

                {/* Only Admins should be able to show the "color setting option" */}
                {isAdmin()?
                 <div className="my-4 justify-between">
                    <label htmlFor="selecter" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Färg</label>
                    <select disabled={!isAdmin()} onChange={(e) => {setColor(e.target.value)}} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                      <option selected={selectedPolygon.fillColor === "red"} value={"red"}>Röd</option>
                      <option selected={selectedPolygon.fillColor === "orange"} value={"orange"}>Orange</option>
                      <option selected={selectedPolygon.fillColor === "gray"} value={"gray"}>Grå</option>
                      <option selected={selectedPolygon.fillColor === "blue"} value={"blue"}>Blå</option>
                    </select>
                  </div>: null
                }
               
              </div>
              <div className="p-4">
                <hr />
                </div>
                {selectedPolygon.index === 1 ? (
                  <div className="px-4">
                    <p className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400">
                      Stoplar ({polygons
                            .filter((x) => x.parentId === selectedPolygon.id).length})
                    </p>
                    <div className="overflow-y-auto max-h-32">
                      <table className="table-fixed w-full">
                        <thead>
                          <tr>
                            <th className="px-1"></th>
                            <th className="px-1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {polygons
                            .filter((x) => x.parentId === selectedPolygon.id)
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((child) => (
                              <tr className="even:bg-gray-200 odd:bg-white" key={Math.random()}>
                                <td className="px-1">{child.name.slice(0)}</td>
                                <td className="px-1 flex justify-end">
                                  <button
                                    onClick={() => {
                                      console.log("Pressing");
                                      dispatch(setSelectedPolygon(child));
                                    }}
                                  >
                                    visa
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}
                </div>

              {/* Only admins should be able to delete or save updates */}
            {isAdmin()?
              <div>
                <div className="mt-6 justify-between flex flex-row p-4">
                  <PrimaryButton onClick={saveChanges}><IoIosSave className="inline text-xl" /> Spara</PrimaryButton>
                  <DangerButton onClick={() => {setIsModalOpen(true)}} ><FaTrashCan className="inline text-xl" /> Delete</DangerButton>
                </div>
              </div>: null
            }
            
          

            {isModalOpen &&
              <ApproveDenyModal onAccept={removePolygonApprove} onDeny={() => {setIsModalOpen(false)}}>Är du säker på att du vill ta bort denna yta?</ApproveDenyModal>  
            }
          </div>
        </div>
      </div>
    );
  }
  
export default SelectedShapeOverlay