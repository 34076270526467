
import { createSlice } from '@reduxjs/toolkit'

const SasTokenSlice = createSlice({
  name: 'SasTokenSlice',
  initialState: null,
  reducers: {
    setSasToken(state, action){
        return action.payload;
    }
  }
})

export const { setSasToken } = SasTokenSlice.actions
export default SasTokenSlice.reducer

