import './App.css';
import Map from './pages/Map';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";

import Authenticated from './Layouts/Authenticated';
import Login from './pages/Login';
import Markers from './pages/Markers/Markers';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/map" element={<Authenticated><Map /></Authenticated>} />
        <Route path="/markers" element={<Authenticated><Markers /></Authenticated>} />
      </Routes>
    </BrowserRouter>
  );

}


export default App;
