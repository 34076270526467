import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const NotifyInfo = (message) => {
    const notify = () => toast.info(message);
    notify();
}

export const NotifySuccess = (message) => {
    const notify = () => toast.success(message);
    notify();
}

export const NotifyError = (message) => {
    const notify = () => toast.error(message);
    notify();
}