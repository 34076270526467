import { PublicClientApplication } from "@azure/msal-browser";

const MSAL_CONFIG = {
    auth: {
        clientId: process.env.REACT_APP_FRONTEND_CLIENT_ID,
        authority: "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANT_ID,
        redirectUri: process.env.REACT_APP_BASE_URL + "/auth-callback",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

// Request backend API access on login
const LOGIN_REQUEST = {
    scopes: ["openid", "profile", process.env.REACT_APP_TOKEN_SCOPE_REQUEST]
};

// Use backend API scope and optional Graph scope when requesting tokens for API calls
const TOKEN_REQUEST = {
    scopes: [process.env.REACT_APP_TOKEN_SCOPE_REQUEST, "User.Read"]
};

const PUBLIC_CLIENT_APPLICATION = new PublicClientApplication(MSAL_CONFIG);
async function initializeMsal() {
    await PUBLIC_CLIENT_APPLICATION.initialize();
}
initializeMsal();

export {
    MSAL_CONFIG,
    LOGIN_REQUEST,
    TOKEN_REQUEST,
    PUBLIC_CLIENT_APPLICATION
};
