import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { useDispatch } from "react-redux";
import SearchModal from "../../../components/Map/SearchModal/SearchModal";

const SearchControl = () => {
    const map = useMap();
    const dispatch = useDispatch();
    const [openSearchModal, setSearchModal] = useState(false);

    window.addEventListener("keydown", (event) => {

        /* Check if Control (or Command on Mac) and 'F' are pressed */
        if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
            event.preventDefault(); // Prevent the default browser search action
            setSearchModal(true);
        }
    });

    useEffect(() => {
        const customControl = L.control({ position: "topright" });

        // Define the onAdd function for the control
        customControl.onAdd = () => {
            var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
            var button = L.DomUtil.create('a', 'leaflet-control-button', container);
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', function(){
                setSearchModal(true)
            });
            button.innerHTML = "Sök"
            container.title = "Title";

            return container;
        };

        customControl.addTo(map);

        // Cleanup on unmount
        return () => {
            customControl.remove();
        };
    }, [map, dispatch]);

    return openSearchModal ? <SearchModal isOpen={openSearchModal} onClose={() => {setSearchModal(false)}}/> : null
};

export default SearchControl;
