import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { FaMapMarkerAlt } from "react-icons/fa";
import ReactDOM from 'react-dom'

/* Control triggers the creation of markers without entering the edit mode */
const MarkerControl = () => {
    const map = useMap();
    
    useEffect(() => {
        var position = window.innerWidth <= 768? "bottomright": "topleft";
        const customControl = L.control({ position: position });

        const startDrawingMarker = (map) => {
            const markerDrawHandler = new L.Draw.Marker(map, {
                icon: new L.Icon.Default(),
                repeatMode: false,
                zIndexOffset: 2000
            });
            markerDrawHandler.enable();
        };

        // Define the onAdd function for the control
        customControl.onAdd = () => {
            const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
            const button = L.DomUtil.create('a', 'leaflet-control-button', container);
            
            // Render the React icon into the button
            const iconElement = document.createElement('div');
            iconElement.className = 'leaflet-control-icon h-full flex flex-col justify-center';
            const root = ReactDOM.createRoot(iconElement);
            root.render(<FaMapMarkerAlt  className="mx-auto text-xl text-gray-700"/>);
            button.appendChild(iconElement);

            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', () => {
                startDrawingMarker(map);
            });

            container.title = "Add Marker";
            return container;
        };

        customControl.addTo(map);

        // Cleanup on unmount
        return () => {
            customControl.remove();
        };
    }, [map]);
};

export default MarkerControl;
