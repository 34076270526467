import ReactDOM from 'react-dom'
import { HashLoader } from 'react-spinners';

const LoadingOverlay = () => {
    return (
      ReactDOM.createPortal(
        <div
            className="fullscreen-overlay fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center z-50 p-4 md:p-52"
            style={{zIndex: 3002}}
        >
            <HashLoader color='white' />
        </div>,
        document.body
      )
    );
  };

export default LoadingOverlay