import { BlobServiceClient } from "@azure/storage-blob"

export const GetSasToken = (jwtToken, callback) => {
    fetch(process.env.REACT_APP_API_BASE_URL + '/markers/imagesas', {
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    })
    .then(res => res.text())
    .then(res => {
        callback(res)
    })
}

const generateUniqSerial = () => {
    return 'xxxx-xxxx-xxx-xxxx'.replace(/[x]/g, function (c) {
      // eslint-disable-next-line no-mixed-operators
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


export const UploadBlob = async (sasToken, blob, callback) => {
    var imageType = blob.type.split('/')[1];
    const imageName = generateUniqSerial() + "." + imageType;
    const blobServiceClient = new BlobServiceClient(sasToken);
    const containerClient = blobServiceClient.getContainerClient("markerimages");
    const blobClient = containerClient.getBlockBlobClient(imageName);
    await blobClient.upload(blob, blob.size)
    callback(imageName)
}

export const UploadBlobsAsync = async (sasToken, blobs, callback) => {
    Promise.all(blobs).then(async blobs => {
        let imageNames = [];
        for (let index = 0; index < blobs.length; index++) {        
            var imageType = blobs[index].type.split('/')[1];
            const imageName = generateUniqSerial() + "." + imageType;
            const blobServiceClient = new BlobServiceClient(sasToken);
            const containerClient = blobServiceClient.getContainerClient("markerimages");
            const blobClient = containerClient.getBlockBlobClient(imageName);
            await blobClient.upload(blobs[index], blobs[index].size)
            imageNames.push(imageName);
        }
        callback(imageNames)
    })
}

export const GetBlobUrl = async (sasToken, imagename, callback) => {
    const blobServiceClient = new BlobServiceClient(sasToken);
    const containerClient = blobServiceClient.getContainerClient("markerimages");
    const blobClient = containerClient.getBlobClient(imagename);
    callback(blobClient.url);
}