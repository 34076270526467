import MainSidebar from "../components/Sidebar/Sidebar";

const MainLayout = ({children}) => {
    return (
        <div>
            <div className="App w-screen h-dvh">
                <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.min.css"/>
                <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css"/>
                <link href="https://cdn.jsdelivr.net/npm/flowbite@2.5.2/dist/flowbite.min.css" rel="stylesheet" />

                <div className='flex flex-row h-screen'>
                <MainSidebar />
                <div className='w-full flex flex-col'>
                    {children}
                </div>
                </div>
                <script src="https://cdn.jsdelivr.net/npm/flowbite@2.5.2/dist/flowbite.min.js"></script>
            </div>
        </div>
    );
}

export default MainLayout