
import { createSlice } from '@reduxjs/toolkit'

const PolygonSlice = createSlice({
  name: 'PolygonSlice',
  initialState: [],
  reducers: {
    addPolygon(state, action){
      return [...state, action.payload];
    },
    setPolygons(state, action){
      return [...action.payload];
    },
    removePolygon(state, action){
      return state.filter(polygon => polygon.id !== action.payload.id);
    },
    updatePolygon(state, action){
      return state.map(polygon =>
        polygon.id === action.payload.id ? action.payload : polygon
      );
    }
  }
})

export const { addPolygon, removePolygon, updatePolygon, setPolygons } = PolygonSlice.actions
export default PolygonSlice.reducer

