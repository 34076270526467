import { useState } from "react";

/* DropdownButton component that acts as an overlay */
const DropDownButton = ({ children, actions }) => {
    const [expanded, setExpanded] = useState(false);
    console.log(actions);
    

    const dropDownClick = (e) => {
        e.stopPropagation(); // Prevent click events from bubbling up
        setExpanded(!expanded);
    };

    return (
        <div className="relative inline-block">
            <div onClick={dropDownClick} className="cursor-pointer">
                {children}
            </div>
            {expanded && (
                <div className="absolute top-full mt-2 w-40 bg-slate-100 border border-slate-300 rounded-xl shadow-lg" onClick={(e) => e.stopPropagation()}>
                    <ul className="text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDividerButton">
                        {actions.map(action => {
                            return (
                                <li onClick={action.action}>
                                    <span className="block hover:bg-gray-200 p-3 rounded-lg">{action.text}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default DropDownButton;
