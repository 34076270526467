import InteractiveMap from "../components/Map/InteractiveMap";
import MainLayout from "../Layouts/MainLayout";
import SelectedShapeOverlay from "../components/Map/SelectedShapeSidebar/SelectedShapeOverlay";

const Map = () => {
    return (
        <MainLayout>
            <link
            rel="stylesheet"
            href="https://unpkg.com/@geoman-io/leaflet-geoman-free@latest/dist/leaflet-geoman.css"
            />
            <script src="https://unpkg.com/@geoman-io/leaflet-geoman-free@latest/dist/leaflet-geoman.js"></script>
            <InteractiveMap satelite={true}/>

            <SelectedShapeOverlay />
        </MainLayout>
    );
}

export default Map