const resizeImage = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                
                // Set canvas dimensions
                let width = img.width;
                let height = img.height;

                // Scale down the image if necessary
                const MAX_FILE_SIZE_MB = 1; // 1MB
                const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
                const MAX_DIMENSION = 1920; // Example: max dimension for resizing

                if (width > MAX_DIMENSION || height > MAX_DIMENSION) {
                    if (width > height) {
                        height *= MAX_DIMENSION / width;
                        width = MAX_DIMENSION;
                    } else {
                        width *= MAX_DIMENSION / height;
                        height = MAX_DIMENSION;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                // Convert to blob and check size
                canvas.toBlob(
                    (blob) => {
                        if (blob.size > MAX_FILE_SIZE_BYTES) {
                            // Reduce quality if still over the size limit
                            canvas.toBlob(
                                (reducedBlob) => resolve(reducedBlob),
                                file.type,
                                0.7 // Adjust quality
                            );
                        } else {
                            resolve(blob);
                        }
                    },
                    file.type,
                    0.9 // Initial quality
                );
            };
            img.src = e.target.result;
        };
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(file);
    });
};

export default resizeImage