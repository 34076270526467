import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPolygon } from "../../../state/slices/SelectedPolygonSlice";
import { setMapPosition } from "../../../state/slices/ViewPositionSlice";
import { CalculateCenterOfShape } from "../../../lib/ShapeHandler";
import { IoMdSearch } from "react-icons/io";

const SearchModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(<SearchModalPop onClose={onClose} />, document.body);
};


const SearchModalPop = ({onClose}) => {
    const [searchString, setSearchString] = useState();
    const polygons = useSelector(state => state.polygons);
    const dispatch = useDispatch();
    const [enable, setEnable] = useState(false);
    const timeoutRef = useRef(null);
    
    /* Based on the search, selects a specific polygon */
    const selectSearchResult = (e) => {
        dispatch(setMapPosition({zoom: 19, centerPosition: CalculateCenterOfShape(e.position)}))
        dispatch(setSelectedPolygon(e));
        onClose();
    }

    /* Triggers whenver the user changesx the input */
    const updateSearchString = (e) => {
      if(e.target.value.toLowerCase() === ""){
        setSearchString(null)
      } else {
        setSearchString(e.target.value.toLowerCase())
      }
    }

    /* Close the popup search "modal" */
    const closeModal = useCallback(() => {
      setEnable(false);
      clearTimeout(timeoutRef.current);
      setTimeout(onClose, 100);
    }, [onClose])

    /* if Escape is pressed, close the search modal */
    const handleKeyDown = useCallback((event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        closeModal();
      }
    }, [closeModal])

    useEffect(() => {
      window.addEventListener("keydown", handleKeyDown);

      // Set timeout to enable transition
      timeoutRef.current = setTimeout(() => {
        setEnable(true);
      }, 100);

      return () => {
        clearTimeout(timeoutRef.current);
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleKeyDown]);
    
    const foundPlygons = polygons.filter(x => x.name.toLowerCase().includes(searchString) || x.description.toLowerCase().includes(searchString));

    return (
      <div className={"overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen bg-black transition-all " + (enable? "bg-opacity-75": "bg-opacity-0")} style={{ zIndex: 3000 }}>
        <div className={"relative p-4 w-full max-w-2xl max-h-full mx-auto transition-all " + (enable? "-translate-y-0":"-translate-y-full" )}>
          <div className={"h-14 w-full rounded-2xl border-gray-400 shadow-xl bg-slate-100 p-2 text-xl flex flex-row transition-all "}>
            <div className={"text-2xl text-slate-500 flex flex-col justify-center"}><IoMdSearch /></div>
            <input onChange={updateSearchString} autoFocus={true} className={"pl-2 h-full w-full text-slate-500 rounded-2xl bg-slate-100 text-xl focus:outline-none"} placeholder="Search..."/>
          </div>
          <div className={"mt-2 h-auto max-h-96 overflow-x-scroll bg-slate-100 rounded-2xl transition-all "}>
            <div className="p-3 mt-2">
              <p className="font-bold">Sökresultat</p>
            </div>
            {foundPlygons.length === 0? 
            <div className="p-3 pt-0">
                <p>Inget resultat...</p>
            </div>
            : <SearchResult selectSearchResult={selectSearchResult} foundPlygons={foundPlygons} />}
          </div>
        </div>
        <div className="px-10 fixed bottom-10 w-full block md:hidden" onClick={closeModal}>
          <div className="bg-slate-100 rounded-2xl w-full text-center text-2xl font-bold text-slate-800 py-3"> 
            Stäng
          </div>
        </div>
      </div>
    );
};

const SearchResult = ({foundPlygons, selectSearchResult}) => {
  /* byggnader */
  const buildings = foundPlygons.filter(x => x.index === 1);

  /* stolpar */
  const areas = foundPlygons.filter(x => x.index === 2);
  
  return (
    <div>
      <div>
        <p className="px-3 font-bold">Byggnader ({buildings.length})</p>
        {buildings.map(x => {
          return (
            <div className="px-3 hover:bg-slate-200 transition-all py-2 lg:py-1" onClick={() => {selectSearchResult(x)}}>
              <p className="font-bold text-slate-700">[{x.name}] - {x.description}</p>
            </div>
          );
        })}
      </div>
      <div>
        <p className="px-3 font-bold">Stolpar ({areas.length})</p>
        {areas.map(x => {
          return (
            <div className="px-3 hover:bg-slate-200 transition-all py-2 lg:py-1" onClick={() => {selectSearchResult(x)}}>
              <p className="font-bold text-slate-700">[{x.name}] - {x.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
} 

export default SearchModal;
