import L from 'leaflet';
/* 
    Given a leaflet polygon, this function creates a Mapzie polygon.
*/
const CreatePolygonFromLayer = (layer, currentSelectd) => {
    const positions = [];
    layer.layer.getLatLngs()[0].forEach((element) => {
        positions.push([element.lat, element.lng]);
    });
    const poly = {
        name: "Unnamed Polygon", // Fallback to default name if user cancels
        position: positions,
        fillColor: "blue",
        index: currentSelectd? 2: 1,
        parentId: currentSelectd? currentSelectd.id: null,
        description: "",
        polygonType: layer.shape
    };
    
    return poly;
}

const CreateRectangleFromLayer = (layer, currentSelectd) => {
    const positions = [];
    layer.layer.getLatLngs()[0].forEach((element) => {
        positions.push([element.lat, element.lng]);
    });
    const poly = {
        name: "Unnamed Polygon", // Fallback to default name if user cancels
        position: positions,
        fillColor: "blue",
        index: currentSelectd? 2: 1,
        parentId: currentSelectd? currentSelectd.id: null,
        description: "",
        polygonType: layer.shape
    };
    return poly;
}

export const GenerateShape = (layer, currentSelectd) => {
    switch(layer.shape){
        case "Polygon":
            return CreatePolygonFromLayer(layer, currentSelectd);
        case "Rectangle": 
            return CreateRectangleFromLayer(layer, currentSelectd);
        default: 
            return CreatePolygonFromLayer(layer, currentSelectd);
    }
}

export const UpdatePolygonFromLayer = (layer, oldPolygon) => {
    const positions = [];
    layer.layer.getLatLngs()[0].forEach((element) => {
        positions.push([element.lat, element.lng]);
    });
}

export const CreateMarkerFromLayer = (layer) => {
    const position = [layer.layer._latlng.lat, layer.layer._latlng.lng]
    const newMarker = {
        position: position,
        description: "Description",
        name: "name",
        id: Math.random(),
        index: 1,
        parentId: null,
        leafletId: layer.layer._leaflet_id,
        images: []
    }
    return newMarker;
}

export const LatLangArrayToPureArray = (LatLngsArray) => {
    const positions = [];
    LatLngsArray.forEach((element) => {
        positions.push([element.lat, element.lng]);
    });
    return positions;
}

export const getVisiblePolygons = (polygons, selectedPolygon) => {
    const polygonsToReturn = [];
    
    /* First get all index 1's */
    polygonsToReturn.push(...(polygons.filter(polygon => polygon.index === 1)));

    // Case 1: Selected polygon is index 1 (parent polygon)
    if (selectedPolygon?.index === 1) {
        // Display all children of the selected parent
        polygonsToReturn.push(...(polygons.filter(polygon => polygon.parentId === selectedPolygon.id)))
    }

    // Case 2: Selected polygon is index 2 (child polygon)
    if (selectedPolygon?.index === 2) {
        // Display the selected polygon and its siblings
        polygonsToReturn.push(...(polygons.filter(polygon => polygon.parentId === selectedPolygon.parentId)))
    }
    return polygonsToReturn
};

export const CalculateCenterOfShape = (points) => {
    let totalLat = 0;
    let totalLng = 0;
    const numPoints = points.length;
    
    points.forEach(point => {
        totalLng += point[0]; // longitude
        totalLat += point[1]; // latitude
    });
    
    const centerLng = totalLng / numPoints;
    const centerLat = totalLat / numPoints;
    
    return [centerLng, centerLat];
}

export const GetTimeSinceAction = (date) => {
    const dateObj = new Date(date);
    const currentDate = new Date();
    const timeDiffInMillis = currentDate - dateObj;
  
    if (timeDiffInMillis < 60000) {
      return "Just nu";
    } else if (timeDiffInMillis < 3600000) {
      const minutes = Math.floor(timeDiffInMillis / 60000);
      return `${minutes} minut${minutes > 1 ? "er" : ""} sedan`;
    } else if (timeDiffInMillis < 86400000) {
      const hours = Math.floor(timeDiffInMillis / 3600000);
      return `${hours} timma${hours > 1 ? "r" : ""} sedan`;
    } else {
      const days = Math.floor(timeDiffInMillis / 86400000);
      return `${days} dag${days > 1 ? "ar" : ""} sedan`;
    }
  };

export const GenerateShapeFromShape = (shape) => {
    switch (shape.polygonType) {
        case "Polygon": 
            return L.polygon(shape.position, {
                color: shape.fillColor || "blue",
                fillOpacity: shape.opacity || 0.8,
                fillColor: shape.fillColor || "blue",
                weight: 1,
                id: shape.id
            });
        default:
            return L.polygon(shape.position, {
                color: shape.fillColor || "blue",
                fillOpacity: shape.opacity || 0.8,
                fillColor: shape.fillColor || "blue",
                weight: 1,
                id: shape.id
            });
    }
}